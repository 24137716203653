<template>
  <v-container fluid class="mb-5">
  <v-card class="mt-5">
    <h1 class="ms-4">LISTADO</h1>
    <v-divider></v-divider>
    <v-data-table 
    @click:row="pushRoute" fixed-header :items="citas.filter(c => new Date(c.datos.fecha) >= new Date())" :headers="citasHeaders" class="fixed-checkbox" item-key="idCita"
      :single-select="true" :loading="loading" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [15, -1],
          }" checkbox-color="secondary" style="cursor:pointer;width: min-content !important">
      <template v-slot:body.prepend="{ headers }">
        <TableFilters :headers="headers" :items="citas" v-model="inlineFilters"></TableFilters>
      </template>
        <template v-slot:item.n="{ item, index}">
          <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
          index+1 }}</td>
        </template>
    </v-data-table>
  </v-card>

  <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
    <v-btn color="primary" fab @click="addCita" large>
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</v-container></template>
  
<script>
import { parseDate, perColumnFilter } from "@/utils";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    confirmado: Array,
    atiende: Array,
    espacios: Array,
    tipoEventos: Array,
    zonas: Array,
    medios: Array,
    colores: Array,
    ubicaciones: Array,
    motivos: Array,
    ficha: Array,
    reserva: Array,
  },
  data() {
    return {
      inlineFilters: {
        idCita: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      citas: [],
      loading: false,
    };
  },
  computed: {
    citasHeaders() {
      return [
        { text: "Nº", value: "n",class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍA", value: "dia", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "HORA INICIO", value: "datos.hInicio", class: "text-no-wrap sticky-header" },
        { text: "DURACIÓN", value: "duracion", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "LUGAR CITA", value: "lugar", class: "text-no-wrap sticky-header" },
        { text: "ESPACIO CITA", value: "espacio", class: "text-no-wrap sticky-header text-center", },
        { text: "MEDIOS", value: "medio", class: "text-no-wrap sticky-header text-center", },
        { text: "QUIEN RESERVA", value: "reserva", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "QUIEN ATIENDE", value: "atiende", class: "text-no-wrap sticky-header" },
        { text: "TIPO EVENTO", value: "tipo", class: "text-no-wrap sticky-header" },
        { text: "MOTIVO", value: "motivo", class: "text-no-wrap sticky-header" },
        { text: "CONF", value: "conf", class: "text-no-wrap sticky-header" },
        { text: "FECHA EVENTO", value: "fechaEv", class: "text-no-wrap sticky-header" },
        { text: "EXISTE FICHA", value: "ficha", class: "text-no-wrap sticky-header" },
        { text: "UBICACIÓN EVENTO", value: "ubicacion", class: "text-no-wrap sticky-header" },
        { text: "NOMBRE CLIENTE", value: "datos.cliente", class: "text-no-wrap sticky-header" },
        { text: "PERSONAS", value: "datos.personas", class: "text-no-wrap sticky-header" },
        { text: "TELÉFONO", value: "datos.tlf", class: "text-no-wrap sticky-header" },
        { text: "EMAIL", value: "datos.email", class: "text-no-wrap sticky-header" },
        { text: "NOTAS", value: "datos.notas", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  mounted() {
    //   if (!(!!(this.$store.getters.getTokenPayload.permisos & this.$store.getters.getPermisos['RESUMEN_RENTABILIDAD']))) this.$router.push({ path: "/" });
    this.getCitass();
  },
  methods: {
    pushRoute(e){
      this.$router.push({ name: 'datosCita', params: { idCita: e.idCita } })
    },
    parseDate,
    async addCita() {
      this.$router.push({ name: "datosCita" })
    },
    async getCitass() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/citas/`,
      });

      this.citas = data.map((m) => {
        return m;
      });
      this.citas.forEach(p => {
        var str = new Date(p.datos.fecha).toLocaleDateString('esp', {weekday : 'short'}).toUpperCase()
        str += ' ' + ('0' + new Date(p.datos.fecha).toLocaleDateString('esp', {day : 'numeric'}).toUpperCase()).slice(-2)
        str += '' + new Date(p.datos.fecha).toLocaleDateString('esp', {month : 'short'}).toUpperCase()
        str += ' ' + new Date(p.datos.fecha).toLocaleDateString('esp', {year : 'numeric'}).slice(-2)
        p.dia = str
        p.duracion = p.datos.duracion + '\''
        p.lugar = p.datos.lugar ? (p.datos.lugar == 69 ? p.datos.lugarDetallado : this.espacios.filter(aux => aux.val == p.datos.lugar)[0].title) : null
        p.espacio = p.datos.espacio ? (p.datos.espacio == 69 ? p.datos.espacioDetallado : this.zonas.filter(aux => aux.val == p.datos.espacio)[0].title) : null
        p.motivo = p.datos.motivo ? (p.datos.motivo == 69 ? p.datos.motivoDetallado : this.motivos.filter(aux => aux.val == p.datos.motivo)[0].title) : null
        if (p.datos.fechaEv) {
          str = new Date(p.datos.fechaEv).toLocaleDateString('esp', {weekday : 'short'}).toUpperCase()
          str += ' ' + ('0' + new Date(p.datos.fechaEv).toLocaleDateString('esp', {day : 'numeric'}).toUpperCase()).slice(-2)
          str += '' + new Date(p.datos.fechaEv).toLocaleDateString('esp', {month : 'short'}).toUpperCase()
          str += ' ' + new Date(p.datos.fechaEv).toLocaleDateString('esp', {year : 'numeric'}).slice(-2)
        }
        else{
          str = "POR DEFINIR"
        }
        p.fechaEv = str
        p.conf = p.datos.confirmado ? (!isNaN(p.datos.confirmado) ? this.confirmado.filter(aux => aux.val == p.datos.confirmado)[0].title : null) : null
        p.ficha = p.datos.ficha ? (!isNaN(p.datos.ficha) ? this.ficha.filter(aux => aux.val == p.datos.ficha)[0].title : null) : null
        p.ubicacion = p.datos.ubicacion ? (p.datos.ubicacion == 10 ? p.datos.ubicacionDetallada : this.ubicaciones.filter(aux => aux.val == p.datos.ubicacion)[0].title) : null
        p.tipo = p.datos.tipo ? (p.datos.tipo ? this.tipoEventos.filter(aux => aux.val == p.datos.tipo)[0].title : null) : null
        p.medio = p.datos.medio ? (p.datos.medio ? this.medios.filter(aux => aux.val == p.datos.medio)[0].title : null) : null
        p.reserva = p.datos.reserva ? (p.datos.reserva ? this.reserva.filter(aux => aux.val == p.datos.reserva)[0].title : null) : null
        p.atiende = p.datos.atiende ? (p.datos.atiende ? this.atiende.filter(aux => aux.val == p.datos.atiende)[0].title : null) : null
      });
      this.loading = false;
    },
  },
};
</script>
<style>.espaciador {
  padding: 5px !important
}</style>