<template>
  <v-card width="100%">
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="primary" @click="setToday">
              Hoy
            </v-btn>
            <template v-if="type == 'month'">
              <v-btn fab text small color="primary" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar" style="width:130px;text-align: center;">
                {{ $refs.calendar.title.toUpperCase().split(" ")[0] }}
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-btn fab text small color="primary" @click="move(-12)">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title.toUpperCase().split(" ")[1] }}
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="move(12)">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <template v-if="type == 'day'">
              <v-btn fab text small color="primary" @click="move(-1)">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                CITAS DIARIAS
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="move(1)">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <template v-if="type == 'week'">
              <v-btn fab text small color="primary" @click="move(-1)">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                CITAS SEMANALES
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="move(1)">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 días</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet class="pa-2">
          <v-calendar
            color="primary"
            ref="calendar"
            v-model="focus"
            :events="[...festivos,...jornadas, ...citas]"
            :event-color="(e) => e.color"
            @click:event="showEvent"
            @contextmenu:event="showEvent"
            @change="getFestivos($event)"
            first-interval="8"
            interval-minutes="60"
            interval-count="13"
            :type="type"
            :key="keyHelper"
            :event-height="40"
            :event-more="false"
            :weekdays="weekdays"
          >
            <template v-slot:event="{ event }">
              <Evento :event="event"/>
            </template>

            <template v-slot:day-label="{ day, present, past }">
              <div class="d-flex">
                <v-btn
                  fab
                  small
                  :elevation="0"
                  :text="!present"
                  :color="present ? 'primary' : ''"
                  :class="{
                    'text-end': true,
                    'grey--text': past,
                    'mr-1': true,
                    'mb-1': true,
                  }"
                >
                  {{ day }}
                </v-btn>
              </div>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            transition="slide-y-transition"
          >
            <DetallesCita
              :selected-event="selectedCita"
              @reload="getCitas"
            />
          </v-menu>

          <div class="d-flex mt-4">
            <v-btn icon outlined :color="$vuetify.theme.dark ? 'white' : 'black'" class="mx-1 justify-center" to="/citas/festivos">
              <v-icon class="mx-auto" left>mdi-party-popper</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-chip class="mx-1" :key="a.val" v-for="a in atiende" :color='"#"+colores.filter(c => c.val == a.val)[0].title' outlined>
              {{ a.title }}
            </v-chip>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      :value="cambioEstado"
      @input="(v) => (v === false ? (cambioEstado = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>Cambiar estado {{ cambioEstado }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="cambioEstado = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <cambio-estado
        @close="cambioEstado = null"
        @reload="getCitas"
        :key="cambioEstado"
        :idEvento="cambioEstado"
      ></cambio-estado>
    </v-dialog>
  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Evento: () => import("./Evento.vue"),
    DetallesCita: () => import("./DetallesCita.vue"),
  },
  props: {
    date: Date,
    confirmado: Array,
    atiende: Array,
    espacios: Array,
    tipoEventos: Array,
    motivos: Array,
    zonas: Array,
    medios: Array,
    colores: Array,
  },
  data() {
    return {
      check: 0,
      menu: false,
      focus: Date.now(),
      ordenTipos: [6, 1, 2, 4, 3, 5],
      ordenEspacios: ["PISCIS", "MASMONZON", "LALANNE", "LAUS", "CATERING"],
      citas: [],
      fechas: [],
      festivos: [],
      jornadas: [],
      meses: [{ txt : "ENERO", val : 1},{ txt : "FEBRERO", val : 2},{ txt : "MARZO", val : 3},{ txt : "ABRIL", val : 4},{ txt : "MAYO", val : 5},{ txt : "JUNIO", val : 6},{ txt : "JULIO", val : 7},{ txt : "AGOSTO", val : 8},{ txt : "SEPTIEMBRE", val : 9},{ txt : "OCTUBRE", val : 10},{ txt : "NOVIEMBRE", val : 11},{ txt : "DICIEMBRE", val : 12}],
      mes: null,
      year: null,
      selectedCita: {},
      selectedElement: null,
      selectedOpen: false,
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        "4day": "4 Días",
      },
      type: "week",
      cambioEstado: null,
      keyHelper: 0,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
    };
  },
  mounted() {
    this.getCitas();
  },
  methods: {
    parseDate,
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    move(t) {
      this.$refs.calendar.move(t);
    },
    
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedCita = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    // showEvent({ nativeEvent, event }) {
    //   this.$router.push( {name : 'datosCita', params : {idCita : event.idCita}})
    // },
    async getFestivos({ start, end }) {
      const { data } = await axios({
        url: "/festivos",
        params: {
          start: start.date,
          end: end.date,
        },
      });
      this.festivos = data;
    },
    async getCitas() {
      const { data } = await axios({
        url: "/citas/",
      });
      
      this.citas = data.map((cita) => {
        return {
          idCita: cita.idCita,
          color: cita.datos.atiende ? ("#"+this.colores.filter(c => c.val == cita.datos.atiende)[0].title) : '',
          timed: true,
					date: cita.datos.fecha,
          start: new Date(cita.datos.fecha + " " + cita.datos.hInicio),
          end: new Date(new Date(cita.datos.fecha + " " + cita.datos.hInicio).getTime() + (parseInt(cita.datos.duracion)*60000)),
          name: [
            cita.datos.hInicio,
            // cita.datos.atiende ? (this.atiende.filter(a => a.val == cita.datos.atiende)[0].title.substring(0,3)) : '',
            cita.datos.tipo ? (this.tipoEventos.filter(t => t.val == cita.datos.tipo)[0].title) : "",
            cita.datos.cliente ? cita.datos.cliente : "",
            cita.datos.motivo ? (cita.datos.motivo == 69 ? cita.datos.motivoDetallado : this.motivos.filter(m => m.val == cita.datos.motivo)[0].title) : '',
            cita.datos.espacio ? (cita.datos.espacio == 69 ? cita.datos.espacioDetallado : this.zonas.filter(e => e.val == cita.datos.espacio)[0].title) : '',
            cita.datos.medio ? (cita.datos.medio == 1 ? "" : (this.medios.filter(m => m.val == cita.datos.medio)[0].title)) : '',
          ]
          .filter((x) => x)
          .join(" - "),
        };
      });
      
      this.selectedOpen = false;
      this.keyHelper++;
    },
  },
};
</script>

<style>
.v-calendar-weekly__day {
  min-height: 80px;
  padding: 3px !important;
}
.v-calendar-weekly__day.v-present {
  border: 1px solid var(--v-primary-base) !important;
}
.v-event {
  height: unset !important;
}
.v-event .v-event--thick {
  height: 25px !important;
}
.v-event-timed{
  overflow-x: hidden;
  overflow-y: auto;
}
</style>