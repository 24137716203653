<template>
    <v-card class="ma-5">
        <Calendario :espacios="espacios" :ubicaciones="ubicaciones" :motivos="motivos" :zonas="zonas" :medios="medios" :confirmado="confirmadoAux" :ficha="ficha" :tipoEventos="tipoEventos" :reserva="reserva" :atiende="atiende" :colores="colores"></Calendario>
        <Listado :espacios="espacios" :ubicaciones="ubicaciones" :motivos="motivos" :zonas="zonas" :medios="medios" :confirmado="confirmado" :ficha="ficha" :tipoEventos="tipoEventos" :reserva="reserva" :atiende="atiende" :colores="colores"></Listado>
    </v-card>
</template>


<script>

import Calendario from "../components/Calendario.vue";
import Listado from "../components/Listado.vue";
export default {
    data(){
        return{
            colores:[
                {title:"FF5733", val : 1}, 
                {title:"FFBD33", val : 2}, 
                {title:"84d404", val : 3}, 
                {title:"7633FF", val : 4}, 
                {title:"33A4FF", val : 5}, 
                {title:"33FFD4", val : 6},
                {title:"e0d209", val : 7},
                ],
            espacios:[
                {title:"PISCIS", val : 1},
                {title:"HOTEL", val : 2},
                {title:"LAUS", val : 3},
                {title:"LALANNE", val : 4},
                {title:"OTROS", val : 69},
            ],
            ubicaciones:[
                {title:"PISCIS", val : 1},
                {title:"HOTEL", val : 2},
                {title:"LAUS", val : 3},
                {title:"LALANNE", val : 4},
                {title:"CATERING", val : 10},
            ],
            motivos:[
                {title:"EVENTO NUEVO", val : 1},
                {title:"REPASO BODAS", val : 2},
                {title:"DUDAS", val : 3},
                {title:"VISITA ESPACIO", val : 4},
                {title:"VISITA TÉCNICA CAT.", val : 5},
                {title:"OTROS", val : 69},
            ],
            zonas:[
                {title:"Z. COMERC.", val : 1},
                {title:"S. JUNTAS", val : 2},
                {title:"HALL", val : 3},
                {title:"BODEGA", val : 4},
                {title:"OTROS", val : 69},
            ],
            medios:[
                {title:"NINGUNO",val:1},
                {title:"ZOOM",val:2},
                {title:"TRADIC.",val:3},
                {title:"IPAD",val:4},
            ],
            confirmado:[
                {title:"NO", val : 0},
                {title:"SI", val : 1},
            ],
            confirmadoAux:[
                {title:"NO CONF", val : 0},
                {title:"CONF", val : 1},
            ],
            ficha:[
                {title:"NO", val : 0},
                {title:"SI", val : 1},
            ],
            tipoEventos:[
                {title:"BODA", val : 1},
                {title:"COMUNIÓN", val : 2},
                {title:"CORPORATIVO", val : 4},
                {title:"EVENTO", val : 3},
                {title:"PRUEBA BODA", val : 5},
                {title:"VACACIONES", val : 100}
            ],
            reserva:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4},
                {title:"BELEN", val : 7}
            ],
            atiende:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4}, 
                {title:"BELEN", val : 7},
                {title:"JAVI", val : 5}, 
                {title:"JOSE", val : 6}
            ],
        }
    },
    components: {
        Calendario,
        Listado
    },
};
</script>